
import Vue from 'vue'
import {Localidad, _Localidad} from "@/models/Localidad";
import {CatalogoService} from "@/services/CatalogoService";
import FormCreate from '@/components/Catalogos/FormLocalidadCreate.vue'
import {mapGetters} from "vuex";

export default Vue.extend({
  name: 'Localidades',
  mixins: [],
  components: {
    FormCreate,
  },
  data: () => ({
    dialog: false,
    service: new CatalogoService('localidades'),
    list: [] as _Localidad[],
    element: new Localidad(),
    search: '',
    headers: [
      {text: 'Entidad', value: 'entidad', align: 'left', width: 200},
      {text: 'Municipio', value: 'municipio', align: 'left', width: 200},
      {text: 'Clave', value: 'cve_loc', align: 'left', width: 150},
      {text: 'Localidad', value: 'nom_loc', align: 'left'},
      {text: 'Opciones', value: 'action', width: 120, sortable: false, align: 'right'},
    ]
  }),
  mounted() {
    this.cargar();
  },
  computed: {
    ...mapGetters({
      role: 'role'
    })
  },
  watch: {},
  methods: {
    async cargar(){
      let {data} = await this.service.getAll();
      if (data.success)
        this.list = data.data;
    },
    editar(item: any) {
      this.element = Object.assign({}, item);
      this.dialog = true;
    },
    activar(item: { id: any; estatus: number }) {
      this.service.activar(item.id).then(res => {
        if (res.data.success)
          this.cargar();
      });
    },
    desactivar(item: { id: any; estatus: number }) {
      this.service.desactivar(item.id).then(res => {
        if (res.data.success)
          this.cargar();
      });
    },
    async eliminar(item: { id: any }) {
      let {data} = await this.service.delete(item.id);
      if (data.success)
        await this.cargar();
    },
    openNew() {
      this.dialog = true;
    },
    closeDialog(reload: boolean) {
      this.dialog = false;
      if(reload)
        this.cargar();
    }
  }
})
